export const partnerContent =  {
    title: "become a partner",

    content: "As a partner of Meta Prime, you unlock access to a range of valuable opportunities. Our highly intuitive and user-friendly interface will enable the future of web3 innovation, commerce, and digital marketing.\n\nWith your custom-designed NFT plot, you can enjoy features like:",

    bullets: [
        "Ready To Use & Highly-Customizable Metaverse Templates",
        "Lifetime Dynamic Ad Space To Increase Web3 Brand Exposure",
        "Permanent Residency In The Heart Of The Metaverse",
        "Montetized Metaverse Funnel To Offer Custom Services"
    ]
}
    


