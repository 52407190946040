import styled from 'styled-components';
import {collectionInfoContent} from 'landModal';
import React, { useState, useEffect, useContext } from 'react';
import { LandingContext } from 'contexts/LandingContext'
import TextDecorator from 'components/textDecorator/TextDecorator';
import ArticleDecorator from 'components/textDecorator/ArticleDecorator';




const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    
`;
const LeftSideContainer = styled.div`
    img {
        width: 500px;
    }
    width: fit-content;
    height: fit-content;
    margin-right: 70px;
    @media (max-width: 768px) {
        width: 294px;
        height: 294px;
        margin-right: 0px;
        margin-top: 23px;
        img {
            width: 100%;
        }
    }
`;

const MetaText = styled.div`
    font-family: Kusanagi;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: 0em;
    text-transform:uppercase ;
    text-align: left;
    color:white;
`;
const ModalContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height: fit-content;
    @media (max-width: 768px) {
        flex-direction: column;
        height: calc(100vh - 42px);
        overflow-y: scroll;
        padding: 0 1rem;
    }
`;
const RightSideContainer = styled.div`
@media (max-width: 768px) {
    width: 100%;
    height: calc(50vh);
    overflow-y: scroll;
    }

`;
const Title = styled.div`
    font-size: 24px;
    line-height: 33.6px;
    color: white;
    font-family: Kusanagi;
    margin-bottom: 16px;
    @media (max-width: 768px) {
        margin-top: 17px;
    }
`;

const TableArea = styled.div`
    display: flex;
    flex-direction: row;
    @media(max-width:490px){
        transform:scale(0.92);
        margin-left:-10px;
    }
`;

const LeftFeature = styled.div`
    width:50% ;
`;
const RightFeature = styled.div`
     width:50% ;
`;

const Content = styled.div`
    max-width: 440px;
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 22.4px;
    color: white;
    white-space: pre-line;  
    vertical-align: bottom;
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 19.6px;
        max-width: 321.16px;
    }
`;
const CloseButton = styled.div`
    position: absolute;
    top: 11px;
    right: 2.4rem;
    height: fit-content;
    cursor: pointer;
    z-index: 4;
    opacity: 0.6;
    &:hover {
        opacity: 1;
    }
    @media (max-width: 768px) {
        top: 0px;
    }
`;

const FeatureContainer = styled.div`
    display:flex ;
    justify-content:space-between;
    flex-direction: row;
    font-family:'Roboto';
    font-weight:bold;
    font-size:18px;
    line-height:140%;
    color:white;
    @media(max-width:736px){
        font-size: 14px;
    }
`;
const Features = styled.div`
    width:75%;
`;

const CellContent = ({ amount, description, size }) => {
    const [content, setContent] = React.useState(amount);
    const [scaleX, setScaleX] = React.useState(1);
    const [scaleY, setScaleY] = useState(1);
    const [fontSizeTop, setFontSizeTop] = React.useState(24);
    const [containerPosition, setContainerPosition] = React.useState('center')
    const { lang } = React.useContext(LandingContext);
    const fontFamily = lang != "en" ? size.width < 490 ? 'SwissraD' : 'Israr-Syria' : 'Kusanagi';
    const { characters } = useContext(LandingContext);
    React.useEffect(() => {
        if (amount.length > 3) {
            const end = amount.slice(amount.length - 3, amount.length);
            const prefix = amount.slice(0, amount.length - 3);
            setContent(prefix + ',' + end);
        } else {
            setContent(amount);
        }
    }, [])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center', height: '100%' }}>
            <TextDecorator direction={'up'} fontSize={fontSizeTop} content={amount} fontFamily={fontFamily} width='fit-content'></TextDecorator>
            <ArticleDecorator content={description} fontFamily='Roboto' fontSize={12} lineHeight={14.4} />
        </div>

    )
}

const RowCorner1 = ({ amount, description, size }) => {
    return (
        <div style={{ border: '1px solid rgba(255,255,255,0.5)', width: '221px', height: '90px', position: 'relative', justifyContent: 'center' }}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-8px', marginLeft: '-2px', position: 'absolute' }}>
                <rect width="14" height="3" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 3 14)" fill="white" />
            </svg>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-8px', marginLeft: '-2px', position: 'absolute' }}>
                <rect width="14" height="3" transform="matrix(-1 0 0 1 14 5.5)" fill="white" />
            </svg>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ bottom: "-7px", marginLeft: '-2px', position: 'absolute' }}>
                <rect width="14" height="3" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 3 14)" fill="white" />
            </svg>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ bottom: "-7px", marginLeft: '-2px', position: 'absolute' }}>
                <rect width="14" height="3" transform="matrix(-1 0 0 1 14 5.5)" fill="white" />
            </svg>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-2px', right: '-13px', position: 'absolute' }}>
                <rect width="14" height="3" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 3 14)" fill="white" />
            </svg>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-8px', right: '-7.5px', position: 'absolute' }}>
                <rect width="14" height="3" transform="matrix(-1 0 0 1 14 5.5)" fill="white" />
            </svg>
            <CellContent amount={amount} description={description} size={size}></CellContent>
        </div>
    )
}

const RowCorner2 = ({ amount, description, size }) => {
    return (
        <div style={{ border: '1px solid rgba(255,255,255,0.5)', width: '221px', height: '90px', marginLeft: '-1px', position: 'relative' }}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-8px', right: '-13px', position: 'absolute' }}>
                <rect width="14" height="3" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 3 14)" fill="white" />
            </svg>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-8px', right: '-2px', position: 'absolute' }}>
                <rect width="14" height="3" transform="matrix(-1 0 0 1 14 5.5)" fill="white" />
            </svg>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ bottom: "-7px", right: '-13px', position: 'absolute' }}>
                <rect width="14" height="3" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 3 14)" fill="white" />
            </svg>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ bottom: "-7px", right: '-2px', position: 'absolute' }}>
                <rect width="14" height="3" transform="matrix(-1 0 0 1 14 5.5)" fill="white" />
            </svg>
            <CellContent amount={amount} description={description} size={size}></CellContent>

        </div>
    )
}

const RowCorner3 = ({ amount, description, size }) => {
    return (
        <div style={{ border: '1px solid rgba(255,255,255,0.5)', width: '221px', height: '90px', position: 'relative' }}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ bottom: "-7px", marginLeft: '-2px', position: 'absolute' }}>
                <rect width="14" height="3" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 3 14)" fill="white" />
            </svg>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ bottom: "-7px", marginLeft: '-2px', position: 'absolute' }}>
                <rect width="14" height="3" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 3 14)" fill="white" />
            </svg>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ bottom: "-7px", marginLeft: '-2px', position: 'absolute' }}>
                <rect width="14" height="3" transform="matrix(-1 0 0 1 14 5.5)" fill="white" />
            </svg>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ bottom: '-2px', right: '-13px', position: 'absolute' }}>
                <rect width="14" height="3" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 3 14)" fill="white" />
            </svg>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ bottom: '-7.5px', right: '-7.5px', position: 'absolute' }}>
                <rect width="14" height="3" transform="matrix(-1 0 0 1 14 5.5)" fill="white" />
            </svg>
            <CellContent amount={amount} description={description} size={size}></CellContent>

        </div>
    )
}


export default function LandModal({isOpen, onClose, size}) {

    const keyEventHandler = (e) => {
        if(e.key === 'Escape') onClose(false);
        document.removeEventListener('keydown', keyEventHandler);
    }
    document.addEventListener('keydown', keyEventHandler);
    const handleModalClose = (e) => {
        if(e.target.id === 'LandModal') {
            onClose(false);
        }
    }
    return (
        isOpen ? <Container id="LandModal" onClick={(e) => handleModalClose(e)}>
            <ModalContainer onTouchMove={e => e.stopPropagation()}>
                <LeftSideContainer>
                    <img src="assets/images/nft/collectionInfo.png" alt="collectionInfo" />
                </LeftSideContainer>
                <RightSideContainer>
                    <Title>
                        {collectionInfoContent.title}
                    </Title>
                    <Content>
                       {collectionInfoContent.content}
                    </Content>

                    <div style={{ marginBottom: '17px' }}>
                                <MetaText>DISTRICT BREAKDOWN:</MetaText>
                            </div>

                            <TableArea>
                                {
                                    collectionInfoContent.tableContent.map((tableCell, index) => {
                                        return (
                                            parseInt(index / 3) === 0 ? (index % 3 === 0 ? <RowCorner1 {...tableCell} key={index} size={size}></RowCorner1> : index % 3 === 1 ? <RowCorner2 {...tableCell} key={index} size={size} /> : <RowCorner3 {...tableCell} key={index} size={size} />) : ""
                                        )
                                    })
                                }
                            </TableArea>

                    <div style={{ marginTop: '20px', marginBottom: "8px" }}>
                                <MetaText>Features</MetaText>
                            </div>
                    <FeatureContainer>
                        <LeftFeature>
                        {
                            collectionInfoContent.bulletsLeft?.map((text, idx) => {
                                return (<div style={{ margin: '15px 0px', display: 'flex', alignItems: 'center' }} key={idx}>
                                    <span style={{ marginRight: '12px'}}><svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.4722 15.4652L12.9579 10.048L7.53866 9.58569M17.8006 19.3346C22.1268 15.7045 22.692 9.2536 19.0612 4.92651C15.431 0.600279 8.97931 0.035828 4.65308 3.66597C0.326842 7.29611 -0.237609 13.7478 3.39253 18.0741C7.02338 22.4011 13.4744 22.9647 17.8006 19.3346Z" stroke="#FF1C6E" strokeWidth="1.24382" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg></span> {text}
                                </div>)
                            })
                        }
                        </LeftFeature>
                        <RightFeature>
                        {
                            collectionInfoContent.bulletsRight?.map((text, idx) => {
                                return (<div style={{ margin: '15px 0px', display: 'flex', alignItems: 'center' }} key={idx}>
                                    <span style={{ marginRight: '12px'}}><svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.4722 15.4652L12.9579 10.048L7.53866 9.58569M17.8006 19.3346C22.1268 15.7045 22.692 9.2536 19.0612 4.92651C15.431 0.600279 8.97931 0.035828 4.65308 3.66597C0.326842 7.29611 -0.237609 13.7478 3.39253 18.0741C7.02338 22.4011 13.4744 22.9647 17.8006 19.3346Z" stroke="#FF1C6E" strokeWidth="1.24382" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg></span> {text}
                                </div>)
                            })
                        }
                        </RightFeature>


                    </FeatureContainer>
                </RightSideContainer>
                <CloseButton onClick={() => onClose(false)}>
                    <img src="assets/images/modal/close-icon.png" width="18px" height="18px" alt="Close"></img>
                </CloseButton>
            </ModalContainer>
        </Container>
        : ''
    )
    
}