import React, {useState} from "react";
import ArticleDecorator from "components/textDecorator/ArticleDecorator";
import TextDecorator from "components/textDecorator/TextDecorator";
import styled from "styled-components";
import Button from 'components/buttons/Button'
import SliderPartner from "components/PartnerCard/SliderPartner";
import { isMobile } from 'react-device-detect';
import { LandingContext } from "contexts/LandingContext";


const Container = styled.div`
  width: 100vw;
  height: ${(props) => props.height}px;
  background-image: url("assets/images/backgrounds/2560/section3.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center bottom;
  @media (max-width: 1920px) {
    background-image: url("assets/images/backgrounds/1920/section3.png");
  }
  @media (max-width: 1440px) {
    background-image: url("assets/images/backgrounds/metaverse.png");
  }
  @media (max-width: 1400px) {
    align-items: end;
  }
  @media (max-width: 1280px) {
    background-image: url("assets/images/backgrounds/1280/section3.png");
  }
  @media (max-width: 490px) {
    background-image: url("assets/images/backgrounds/mobile/section3.png");
  }
`;
const PageContainer = styled.div`
  height: ${(props) => props.height - 194}px;
  padding-top: 116px;
  padding-bottom: 78px;
  @media (max-width: 1400px) {
    height: ${(props) => props.height - 194}px;
  }
`;
const BodyContainer = styled.div`
  max-width: 1800px;
  width: calc(100vw - 120px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1200px) {
    flex-direction: column;
    flex-direction: column-reverse;
    justify-content: space-around;
  }
  @media (max-width: 490px) {
    height: 80vh;
    justify-content: flex-end;
    width: calc(100vw - 48px);
  }
`;

const Content = styled.div`
  @media(max-width:1400px){
    height:410px;
    overflow:scroll;
    width:100%;
    margin-bottom:50px;
  }
  @media(max-height:900px){
    height:410px;
    overflow:scroll;
  }
  @media(max-width:490px){
    max-height:324px;
    overflow-x: hidden;
  } 
`
const LeftContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;

`;
const RightContainer = styled.div`
  display: flex;
  height:360px;
  width:100%;
  max-width:810px;
  justify-content:center;
  align-items:center;
  overflow:hidden;
 
`;
const FooterContainer = styled.div`
  display: none;
  @media (max-width: 490px) {
    display: block;
    height: 52px;
  }
`;
const Context = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 3px;
    @media(max-width:490px){
        margin-bottom:20px;
    }
`;
const Icon = styled.div`
    margin-right: 8px;
    margin-top:20px ;
    @media (max-width: 1300px) {
        margin-top: 0px;
    }
    @media (max-height: 800px) {
        margin-top: 5px;
    }
`;




const ArticlePart = ({text, size, contentList}) =>  {
  return (<Content>
  <ArticleDecorator content={text} fontFamily="Roboto" fontSize={16} lineHeight={22.4} width={size.width > 1600 ? "732px" : size.width < 490 ? "342px" : "493px"}></ArticleDecorator>
  {
    contentList.map((txt, index) => {
      return <Context key={index}>
        <Icon>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="5.65723" width="8" height="8" transform="rotate(-45 0 5.65723)" fill="#FF1C6E" />
          </svg>
        </Icon>
        <ArticleDecorator content={txt}
          fontFamily='Roboto'
          fontSize={18}
          weight={700}
          lineHeight={22.4}
        />
      </Context>
    })
  }
  </Content>);
}



export default function Partner({ size, direction }) {
  const querterRef = React.useRef();
  const content = "Access a new era of web3 e-commerce opportunities with your own HQ in the metaverse, custom designed by our globally renowned artists to showcase your brand and convey your business objectives.";
  
  const handleWheel = (e) => {
    e.stopPropagation();
  };
  const handleTouchStart = e => {
    e.stopPropagation();
  }
  const handleTouchEnd = (e) => {

    e.stopPropagation();

  }
  const {setPartnerModalOpen} = React.useContext(LandingContext);




  const fontSize = isMobile ? 14 : 28;


  return (
    <Container height={size.height}>
      {/* <GeneralModal id="PartnerModal" show={showModal} onClose={() => setShowModal(false)}>
        <ArticlePart text={text} size={size} contentList={contentList} />
        
      </GeneralModal> */}
      
      <PageContainer height={size.height}>
        <BodyContainer height={size.height}>
          <Content>
            <LeftContainer
              onWheel={(e) => handleWheel(e)}
              onTouchMoveCapture={(e) => e.stopPropagation()}
              onTouchStart={(e) => handleTouchStart(e)}
              onTouchEnd={(e) => handleTouchEnd(e)}
              onTouchCancelCapture={(e) => handleTouchEnd(e)}
              ref={querterRef}
            >
              <TextDecorator uppercase="none" content="Our partners" direction="up" fontFamily="Kusanagi" fontSize={fontSize}></TextDecorator>
              <div style={{ height: '14px' }}></div>
              <ArticleDecorator content={content} fontFamily="Roboto" fontSize={16} lineHeight={22.4} width={size.width > 1600 ? '732px' : size.width < 490 ? '342px' : '493px'}></ArticleDecorator>
              <div style={{ height: '14px' }}></div>
              
              {!isMobile && (
                <Content>
                  <TextDecorator uppercase="none" content="Do you want to" direction="up" fontFamily="Kusanagi" fontSize={fontSize}></TextDecorator>
                  <TextDecorator uppercase="none" content="become a partner" direction="up" fontFamily="Kusanagi" fontSize={fontSize}></TextDecorator>
                </Content>
              )}
              <div style={{ height: '20px' }}></div>
              <Button onClick={() => setPartnerModalOpen(true)} >become a partner</Button>
            </LeftContainer>
          </Content>
          <SliderPartner />
        </BodyContainer>
      </PageContainer>
    </Container>
  );

}
