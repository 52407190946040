export const nftDescriptions = [ 
    {
      title: "sky islands",
      content: "Sky Islands sprawl across the sky high above Crystal Valley, providing secluded retreats for academic study and luxurious living. Their distance from ground-dwelling threats allowed them to build large and wondrous structures far from the troubles of the world below. Its rich Aether Crystal deposits serve as a conduit for the energy that powers this wondrous floating landscape.\n\nThe islands are home to some of Aetherya's most wealthy and powerful families. Hawk Station, where the Metagalactic Alliance Guard keeps watch for dangers from Sky Pirates and extraterrestrial aggressors, is also located here. Sky Islands is a peaceful and tranquil place to live, with the world's greatest views accessible no matter where you are. Sky Islands is where the elite congregate and decisions made here have a significant impact on all Aetheryans' lives.",
    },
     {
      title: "aether sands",
      content: "The Aether Sands biome can best be described as a heady mix of the ancient and the modern. The angular architecture from ancient times combines with the skyscrapers of the modern era, creating an interesting mix of styles.\n\nThis biome is largely unexplored, and there are many ruins of massive Kroaton structures which are still untouched. One of the most fascinating features of this biome is the abundance of Aether Crystal energy, such that the sands sometimes seem to glow on the horizon at night, due to the concentration of energy in the area due to Crystal dust in the sands, blown from Crystal Valley to the north. This biome is an interesting place to explore, and offers a unique flavor of adventure and lifestyle that cannot be found elsewhere. Those seeking undiscovered Kroaton ruins will surely find them in The Aether Sands.",
    },
    {
      title: "arkterra",
      content:"The northernmost district on Aetherya, Arkterra is a realm of quiet and natural splendor. Despite being perpetually blanketed by ice and snow, the creatures here are vibrant and diverse. Predators give way to nimble, active prey as the size of predators increases. Many crystal and mineral deposits can be found in the region. However, in the mountainous regions, be wary. It is claimed that long-extinct creatures have begun to thaw. Who knows what dangers they might pose!\n\nIronically, it was the Ersatz who claimed this region as their own. Instead of a haven from their duties in society, they have molded Arkterra into a luxurious, welcoming refuge for all organic life on Aetherya. They seem to prefer an unadorned, naturally harmonious style that is in harmony with nature. The Ersatz's Martial Arts tournaments are an amazing sight for tourists and an honored tradition for traveling athletes.",
    },
    {
      title: "thousand lakes",
      content:"The Thousand Lakes is a biome found to the east of Meta City. The Kin, a race of cat-like beings, live here and have taken it upon themselves to manage it, cultivating it's atmospheric beauty. There is plenty of waterfront property and shipping opportunities, and the lakes are also good for hunting and fishing. Boat travel is popular in this area. The area is a popular resort spot on Aetherya, especially for those looking to get away from the hustle and bustle of Meta City or the politics of The Sky Islands.\n\nThe Hylorians as well have laid claim to many of the lake properties. It is rumored that they have secretly built bases in the area, but most accept that they came to the lakes for the same reason as the other races; To escape the worries of the world outside. There is a feeling of peace and stillness here that is hard to find in other parts of the world.",
    },
    {
      title: "sylvan rest",
      content:"Sylvan Rest is a vast, untamed wilderness home to all manner of creatures waiting to be discovered. The Kin and the Untainted Ones both call this place home, but for different reasons. The Kin have adapted to the natural habitat extremely well, often in conflicting interest to The Untainted Ones, who come to Summergrove to study the strange energies that weave their ways between the trees.\n\nThe Untainted Ones are drawn to magical forces and they believe that these energies are in fact a type of magical force that they have never encountered before. It's a quiet place to live with massive trees that provide plenty of shade and construction material. The homes and lodges in Wildlands are often elaborately designed and seek to blend in with the natural world. They are built at the base of massive trees or sometimes even through the mountains themselves.\n\nThe architecture moves and grows with the local plantlife, never overtaking it. Natural beauty is preserved at all costs here, even during extensive mining operations. The people of SummerGrove are hardy and industrious, who understand the importance of living in harmony with their surroundings.",
    },
    {
      title: "ocean side",
      content:"Oceanside is a beach resort that is popular with Neptunians due to its proximity to their adopted homeland of Hylora. It is also known far and wide as a peaceful retreat for humans and other species, offering beautiful views and luxury accommodations for those who have earned a break. The architecture of the buildings here mimic the shell-like shapes of the creatures that live in the oceans, providing a visual reminder of the natural beauty that surrounds us. The shores of Oceanside are also home to the gateway to the underwater biome of Hylora, which is a popular destination for divers and other aquatic enthusiasts.\n\nKroaton relics are frequently found washed up on the shores here, providing a fascinating glimpse into the lost civilization of that ancient race. All in all, Oceanside is a special place where visitors can relax and rejuvenate in beautiful surroundings. So if you're looking for a place to get away from it all, this is definitely the spot for you.",
    },
    {
      title: "corsair",
      content:"Look up into the night sky and, floating in the middle of the vast sea of stars, you might catch a glimpse of Corsair. To some, this sight means trouble is on the horizon. To others, it means freedom. Corsair is the home of Aetherya's feared Sky Pirates. On the outside, it looks like a massive floating fortress fused together from pieces of other ships, but inside you will find a lively, bustling city. The ship is home to Aetherya's outsiders, and yes, sometimes it's criminals.\n\nThe pirates of Corsair are not always the scoundrels they might sometimes seem. Most are just people who didn't have anywhere else to go. Here, at least, they are free to be themselves. Others were born here and never knew anything outside the ship's walls. Here honor and loyalty are a way of life and the laws, what few there are, are strictly enforced. Those seeking a life of adventure and excitement where they are free to be themselves will always have a home on Corsair.",
    },
    {
      title: "meta prime",
      content:"The capital city of Aetherya, Meta Prime is a massive metropolis located on a central landmass to the west of Crystal Valley. It's one of the most technologically advanced and commercially active biomes in all of Aetherya, with art galleries, theaters, nightclubs, and skyscrapers. The Metagalactic Alliance's best and brightest walk these streets.  Meta City is a center of art and music, and is host to the best parties on Aetherya. Races from around the multiverse live side by side here, giving each district an eclectic character all its own. Residents here go about their lives in safety and comfort. Between the attractions, the sounds, and the parties, This is one of the most beautiful cities in the multiverse. It's no surprise that everyone wants to live here. \n\nMeta Prime does have another side, however; a dark underside where shady dealings take place in the shadows. The rich and powerful live here, far from the prying eyes of commoners. There are also rumors of ancient secrets buried beneath these bustling streets. As a result, many relics have made their way to the surface, each more wonderious than the last." ,
    },
    {
      title: "overwatch",
      content:"Overwatch is a space station in orbit around Aetherya, built by the first humans who arrived on the planet. It has since become the meeting place for first contact events with many different races. The station also serves as a defensive outpost against extraterrestrial threats, equipped with an array of Aether Crystal-powered cannons. It is also a base for explorers, historians, and geologists who study the planet below and the stars beyond.\n\nOverwatch Station is divided into different habitats, each suitable for the comfort of many different races.  It's home to a diverse array of cultures and interspecies relationships. It is a fascinating place to visit, and those who do are sure to have an enriching experience.",
    },
    {
      title: "hyloria",
      content:"Hyloria is a beautiful, thriving underwater city built around the ruins of an ancient Kroaton deep sea research facility. The city is home to the Hylorians, whose biology is well suited to life in the watery depths. The Hylorians have managed to create a comfortable and prosperous society for themselves, while still respecting the balance of undersea life around them in their architecture. The city is entirely illuminated by bioluminescent creatures, which have been cultivated for their natural beauty.\n\nThey have also installed “The Bridge”, which is the preferred method of travel. A massive elevator that moves visitors from the shores of Oceanside all the way to Hyloria in a safe and comfortable environment without ever having to experience the negative effects of deep sea pressure.\n\nThe Hylorians are notoriously tight-lipped about the discoveries they've made in their new home, but opening their doors to outsiders is a positive sign that they may be ready to cooperate more with the surface world." ,
    },
    {
      title: "crystal v.",
      content:"Crystal Valley is a unique biome that could be found on Aetherya. The ancient ruins and massive Aether Crystal deposits are rumored to be the origin of the Aether Crystals on Aetherya, but this has yet to be proven. Although the valley has been occupied by mining operations since the earliest days of The Metagate, The Wandering Ones were the first to populate this biome as residents and can often be found, true to their name, wandering the massive wild crystals and ruins, sampling the energy they generate for themselves. The valley is now home to a variety of permanent residents and visitors, drawn to the unique environment and the promise of discovery. The valley is also known for its massive kroaton ruins, which are still being explored by intrepid adventurers and those seeking the sage advice of a helpful Wanderer.",
    },
    {
      title: "motherboard",
      content:"Motherboard is a massive network of tunnels and underground caverns that is still being explored and mapped. These structures were built by the Kroaton and their purpose is still being discovered. Motherboard, as it is known, is a densely packed maze of circuit-like structures and ancient technologically advanced relics. The Ersatz race was discovered here while the city above was being constructed. Whatever purpose they served originally in researching or maintaining Motherboard's network is also the subject of debate, but using Neo Aethertech technology, many sections have been restored to functionality.\n\nMeta Prime rests above a convergence point of several key Motherboard tunnels. This is why the city was chosen as the capital of Kroaton society. Many believe that Motherboard is actually a single massive structure that snakes out throughout the entire planet. Whether this is true or not remains to be seen. What is known for certain, however, is that Motherboard is full of secrets and hidden dangers.",
    },
    {
      title: "singularity",
      content:"Singularity is not a landmass, nor a manmade structure of any known origin. It is a black hole that suddenly and catastrophically made its presence known in recent years, threatening to consume Aetherya's sun. Only the engineering marvel known as Solace has slowed its hunger. Slowed, but also allowed for an unexpected opportunity to explore.\n\nFor those brave enough to venture into Singularity, be warned – it is not an easy place to traverse. The strange creatures that live there can be deadly, and the architecture can be confusing and disorienting, even maddening. But those who make it to the other side will find a place unlike any other, full of mystery and secrets just waiting to be discovered. So if you're feeling adventurous, pack your bags and venture into The Black Cascade – it'll be the journey of a lifetime, one way or another.",
    },
    {
      title: "solace",
      content:"Solace is a dyson sphere built to protect Aetherya’s sun from the black hole that threatens its sun. It was constructed by The Ersatz, using technology developed by The Wandering Ones. It is powered by Aether Crystal energy and is home to many different races who work tirelessly to maintain it's important functions, including the Salamarris and Ersatz, who are less troubled than other races by the sun's heat and radiation.\n\nSolace’s elaborate, technologically advanced architecture and enclosed protective biomes make it a marvel to behold. The inhabitants of the Solace use the resources provided by these biomes to sustain themselves and to build and maintain the great technological wonders of the sphere.It is a popular destination for academics seeking to study it's technology, most of which is nearly as complex and mysterious as the Metagate itself.",
    },
    {
      title: "pyromount",
      content:"Pyromount is an active volcano that, although hidden behind  clouds of dust, ash, and spewing lava so large that it's visible from the shores of Meta Prime far to the east. Not only have the Salamarris lived in this harsh environment for decades, but many other creatures have evolved to adapt and flourish there as well. Visitors of other races choose to live in specially preserved biomes to regulate the temperature and to prevent ash and lava from destroying their homes. The Salmarris, however, roam the mountainside freely, far from the hustle and bustle of the rest of Aetheryian society.\n\nAdventurers looking for the challenge of a lifetime would do well to listen to their advice before going off alone in search of the treasures hidden within.",
    },
    {
      title: "el dorado",
      content:"At the edge of Atheryan civilization lies a vast jungle known as TEl Dorado. As the name implies, it's an ancient city made of solid gold, nestled secretly in an untamed land filled with strange wildlife, not all of which has been fully cataloged. These creatures are gathered to this land by the natural resources found within the thick underbrush and naturally abundant Aether Crystal energy. The Simia were the first to settle this region, laying claim to the ancient golden temples they discovered, and have adapted well to the environment. They can maneuver freely without disturbing the natural landscape and have discovered methods of mining Aether Crystals with minimal impact. Researchers discovered that the gold used to build the ancient civilization here possesses unique properties that made it essential to the construction of Solace. A portal with direct access to Solace was built in the central Simia settlement.\n\n Most of El Dorado remains untouched by civilization, and the Simia, despite their aggressive position in the Aetheryan economy, are fierce protectors of their land. Trade routes and residences are rapidly expanding, but El Dorado will always remain beautiful and untamed.",
    }];

   
