import React from 'react';
import styled from 'styled-components';
import TextDecorator from 'components/textDecorator/TextDecorator';
import { txt } from 'lang';
import { LandingContext } from 'contexts/LandingContext';
import MobileMemberItem from 'components/Member/MobileMemberItem';


const WrappedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: ${props => props.height}px;
  justify-content:center;
  align-items:center;
  background-image : url('assets/images/backgrounds/2560/section9.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media(max-width:1920px){
        background-image : url('assets/images/backgrounds/1920/section9.png');
  }
  @media(max-width:1440px){
    background-image : url('assets/images/backgrounds/team.png');
  }
  @media(max-width:1400px){
       justify-content:flex-end ;
  }  
  @media (max-width: 1280px) {
        background-image: url('assets/images/backgrounds/1280/section9.png');
  }
  @media(max-width:490px){
    background-image : url('assets/images/backgrounds/mobile/section9.png');
  }

`;
const PageContainer = styled.div`
    height: ${props => props.height - 194}px;
    @media(max-width:1400px){
        height: ${props => props.height - 116}px;
    }
`;
const Container = styled.div`
  height: 100%;
  display: flex;
  padding:0;
  max-width: 1800px;
  width:calc(100vw - 120px);
  align-items: center;
  justify-content:center;
  @media(max-width:490px){
    width:calc(100vw - 48px);
  }

`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  height:100%;
  width:100%;
  align-items: center;
  justify-content: space-between;
  @media(max-width:1024px) {
    flex-direction: column;
  }
`;
const PageContent = styled.div`
  width:730px;
  min-width: 481px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  @media(max-width:1600px) {
    width: 481px;
  }
  @media(max-width:768px) {
    width:100%;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media (max-width: 549px) {
    width: 200px;
    min-width: 316px;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media (max-width: 490px) {
    width: 85vw;
    min-width: 80vw;
  }

`;

const MemberContainer = styled.div`
    @media(max-width:1400px){
        overflow:hidden;
        scrollbar-width: none;
        padding-bottom:52px;
    }
    @media(max-width:490px){
  }
`;
const MemberPhotoContainer = styled.div`
  display:flex;
  flex-direction:column;
  margin-left: 5%;
  @media(max-width:1400px){
    transform:scale(1) ;
  }
  @media(max-width:1400px) {
    transform:scale(1) ;
    margin-left: 0%;

  }
  @media (max-width: 780px) {
    margin-top: 120px;
  }
`;

const SecondMember = styled.div`
  margin-left:93px;
  @media (max-width: 1024px) {
    margin-left:0px;
    height: 325px;
    margin-bottom: 85px;
  }
`;
;

const TeamIntro = ({ size }) => {
  const { lang } = React.useContext(LandingContext);
  const content = txt('section10', 'body', lang);
  const fontFamily = lang != "en" ? size.width < 490 ? 'SwissraD' : 'Israr-Syria' : 'Kusanagi';
  const querterRef = React.useRef();

  const content2 = "Mohannad's unique technology background has put him at the forefront of emerging trends, industries, and cutting-edge technology. As technology lead, Mohannad harnesses the power of his visionary mindset to bring clarity to the brand's technological vision, strategies, and roadmap."

  const handleLink = (name) => {
    if (name === "anmar") {
      window.open('https://www.linkedin.com/in/anmaralharbi/');
    }
    else if (name === 'Mohannad') {
      window.open('https://www.linkedin.com/in/mohannadotaibi/')
    }
    window.open('https://www.linkedin.com/in/leitchsteve/')
  }


  return (
    <WrappedContainer height={size.height}>
      <PageContainer height={size.height}>
        <Container>
          <Content>
            <PageContent>
              <TextDecorator direction={'up'}
                uppercase="none"
                fontSize={32}
                content={'The team'}
                fontFamily={'Kusanagi'}
                width='fit-content'></TextDecorator>
             
            </PageContent>
            <MemberContainer>
              <MemberPhotoContainer ref={querterRef}>
                    <SecondMember><MobileMemberItem url="https://www.linkedin.com/in/mohannadotaibi/" member={'Mohannad.png'} des={content2} name={'Mohannad'} position={'Technology'} /></SecondMember>
              </MemberPhotoContainer>
            </MemberContainer>
          </Content>
        </Container>
      </PageContainer>
    </WrappedContainer>
  );
};

export default TeamIntro;