/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import React from 'react';

const Container = styled.div`
    width: 518px;
    height: 518px;
    position: relative;
    @media (max-width: 1280px) {
        width: 294px;
        height: 294px;
        transform: scale(0.567);
        transform-origin: top left;
    }
    @media (max-width: 768px) {
        transform: scale(0.45);
        transform-origin: top left;
    }


`;
const ItemImageContainer = styled.div`
    position: absolute;
    top: -1px;
    left: -1px;
    width: 518px;
    height: 518px;
    clip-path: path("M257.524 12.8652H257.731L257.877 12.719L270.112 0.5H519.5V420.871L507.266 433.09L507.119 433.236V433.443V507.427L495.031 519.5H24.9688L0.5 495.062V24.9377L12.5879 12.8652H257.524Z");
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ItemImage = styled.img`
    width: 916px;
    height: 518px;

`;

export default function ModalItem({src}) {
    const alt = "MetagateNFT-"+ src;
    const [loaded, setLoaded] = React.useState(false);
    const imgUrl = React.useRef("assets/images/nft/lowResolution/" + src + '.png');

    const handleImageLoaded  = () => {
            setLoaded(true);
    };

    return (
        <Container>
            <div style={{ display: loaded ? "block" : "none", transition: 'opacity 0.4s ease'}} >
            {
                <img  width="500px" alt={alt} src="assets/images/modal/itemBack.png"></img>
            }
            <ItemImageContainer>
                <ItemImage src={imgUrl.current} alt={alt} onLoad={handleImageLoaded} ></ItemImage>
            </ItemImageContainer>
            </div>
        </Container>
    )
}