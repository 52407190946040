import styled from 'styled-components';
import ModalItem from 'components/modal/ModalItem';
import {nftDescriptions} from 'LandDescriptions';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LeftSideContainer = styled.div`
    width: fit-content;
    height: fit-content;
    margin-right: 70px;
    @media (max-width: 768px) {
        width: 230px;
        height: 225px;
        margin-right: 0px;
        margin-top: 23px;
    }
`;
const ModalContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height: fit-content;
    @media (max-width: 768px) {
        flex-direction: column;
        height: calc(100vh - 43px);
    }
`;
const RightSideContainer = styled.div`

`;
const Title = styled.div`
    font-size: 24px;
    line-height: 33.6px;
    color: white;
    font-family: Kusanagi;
    margin-bottom: 16px;
    @media (max-width: 768px) {
        margin-top: 17px;
    }
`;

const Content = styled.div`
    max-width: 440px;
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 22.4px;
    color: white;
    white-space: pre-line;  
    vertical-align: bottom;
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 19.6px;
        max-width: 321.16px;
        height: calc(45vh);
        overflow-y: scroll;
    }
`;
const CloseButton = styled.div`
    position: absolute;
    top: 11px;
    right: 2.4rem;
    height: fit-content;
    cursor: pointer;
    z-index: 4;
    opacity: 0.6;
    &:hover {
        opacity: 1;
    }
    @media (max-width: 768px) {
        top: 0px;
    }
`;



export default function MetaverseModal ({isOpen, onClose, src, title}) {

    const keyEventHandler = (e) => {
        if(e.key === 'Escape') onClose(false);
        document.removeEventListener('keydown', keyEventHandler);
    }
    document.addEventListener('keydown', keyEventHandler);
    const handleModalClose = (e) => {
        if(e.target.id === 'MetaverseModal') {
            onClose(false);
        }
    }
    const description = nftDescriptions.find((land) => land.title === title);
    

    return (
        isOpen ? <Container id="MetaverseModal" onClick={(e) => handleModalClose(e)}>
            <ModalContainer onTouchMove={e => e.stopPropagation()}>
                <LeftSideContainer>
                    <ModalItem src={src}></ModalItem>
                </LeftSideContainer>
                <RightSideContainer>
                    <Title>
                        {title}
                    </Title>
                    <Content>
                       {description?.content}
                    </Content>
                </RightSideContainer>
                <CloseButton onClick={() => onClose(false)}>
                    <img src="assets/images/modal/close-icon.png" width="18px" height="18px" alt="Close"></img>
                </CloseButton>
            </ModalContainer>
        </Container>
        : ''
    )
    
}