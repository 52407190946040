import styled from 'styled-components';
import {partnerContent} from 'becomePartner';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    
`;
const LeftSideContainer = styled.div`
    img {
        width: 500px;
    }
    width: fit-content;
    height: fit-content;
    margin-right: 70px;
    @media (max-width: 768px) {
        width: 294px;
        height: 294px;
        margin-right: 0px;
        margin-top: 23px;
        img {
            width: 100%;
        }
    }
`;
const ModalContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height: fit-content;
    @media (max-width: 768px) {
        flex-direction: column;
        height: calc(100vh - 42px);
        overflow-y: scroll;
        padding: 0 1rem;
    }
`;
const RightSideContainer = styled.div`
        @media (max-width: 768px) {
        height: calc(45vh);
        overflow-y: scroll;
        }

`;
const Title = styled.div`
    font-size: 24px;
    line-height: 33.6px;
    color: white;
    font-family: Kusanagi;
    margin-bottom: 16px;
    @media (max-width: 768px) {
        margin-top: 17px;
    }
`;

const Content = styled.div`
    max-width: 440px;
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 22.4px;
    color: white;
    white-space: pre-line;  
    vertical-align: bottom;
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 19.6px;
        max-width: 321.16px;
    }
`;
const CloseButton = styled.div`
    position: absolute;
    top: 11px;
    right: 2.4rem;
    height: fit-content;
    cursor: pointer;
    z-index: 4;
    opacity: 0.6;
    &:hover {
        opacity: 1;
    }
    @media (max-width: 768px) {
        top: 0px;
    }
`;

const FeatureContainer = styled.div`
    display:flex ;
    justify-content:space-between;
    flex-direction: row;
    font-family:'Roboto';
    font-weight:bold;
    font-size:18px;
    line-height:140%;
    color:white;
    @media(max-width:736px){
        font-size: 14px;
    }
`;
const Features = styled.div`
    width:75%;

    @media (max-width: 768px) {
        width: 90%;
    }
`;


export default function PartnerModal({isOpen, onClose, src, title}) {

    const keyEventHandler = (e) => {
        if(e.key === 'Escape') onClose(false);
        document.removeEventListener('keydown', keyEventHandler);
    }
    document.addEventListener('keydown', keyEventHandler);
    const handleModalClose = (e) => {
        if(e.target.id === 'PartnerModal') {
            onClose(false);
        }
    }
    return (
        isOpen ? <Container id="PartnerModal" onClick={(e) => handleModalClose(e)}>
            <ModalContainer onTouchMove={e => e.stopPropagation()}>
                <LeftSideContainer>
                   
                    <img src={src} alt="becomepartner" /> 
                </LeftSideContainer>
                <RightSideContainer>
                    <Title>
                        {partnerContent.title}
                    </Title>
                    <Content>
                       {partnerContent.content}
                    </Content>
                    <FeatureContainer>
                        <Features>
                        {
                            partnerContent.bullets?.map((text, idx) => {
                                return (<div style={{ margin: '15px 0px', display: 'flex', alignItems: 'center' }} key={idx}>
                                    <span style={{ marginRight: '12px'}}><svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.4722 15.4652L12.9579 10.048L7.53866 9.58569M17.8006 19.3346C22.1268 15.7045 22.692 9.2536 19.0612 4.92651C15.431 0.600279 8.97931 0.035828 4.65308 3.66597C0.326842 7.29611 -0.237609 13.7478 3.39253 18.0741C7.02338 22.4011 13.4744 22.9647 17.8006 19.3346Z" stroke="#FF1C6E" strokeWidth="1.24382" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg></span> {text}
                                </div>)
                            })
                        }
                        </Features>
                    </FeatureContainer>
                </RightSideContainer>
                <CloseButton onClick={() => onClose(false)}>
                    <img src="assets/images/modal/close-icon.png" width="18px" height="18px" alt="Close"></img>
                </CloseButton>
            </ModalContainer>
        </Container>
        : ''
    )
    
}