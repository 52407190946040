export const collectionInfoContent =  {
    title: "COLLECTION INFO",

    content: "Every premium property designed by our award-winning artists comes jam-packed with a wealth of features, benefits, and rewards.",

    bulletsLeft: [
        "Rentable",
        "NFT Gallery & Shop",
        "Web3 Social Homebase",
        "Monetized Streaming"
    ],
    bulletsRight: [
        "Token Airdrops",
        "Play-to-Earn PVE & PVP",
        "Genesis Monster NFT Airdrop",
        "Lifetime Ad Space"
    ],

    tableContent: [
        {
            amount: '16',
            description: 'Districts'
        },
        {
            amount: '500',
            description: 'Capitals'
        },
        {
            amount: '80',
            description: 'Mythics'
        },
    ]

}



