export function handleTouchScrollingWithPage(e, querterRef) {
    e.stopPropagation();
    const isBottom = querterRef.current.scrollHeight - Math.floor(querterRef.current.scrollTop) === querterRef.current.clientHeight;
    const isTop = querterRef.current.scrollTop === 0;
    if (isBottom) {
        console.log('Top');
        window.fullpage_api.moveSectionDown();
    }
    else if (isTop) {
        console.log('Bottom');
        window.fullpage_api.moveSectionUp();
    }
    else {
        e.stopPropagation();
    }
}